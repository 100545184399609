@import "./normalize.css";

:root {
  --color-primary: #6d0707;
  --color-primary2: #0a9947;
  --color-primary-old: #e08907;
  --color-blue: #2762f8;
  --color-secondary: #001c46;
  --color-third: #0b40c9;
  --color-light: #f2f5fa;
  --color-info: #6d0707;
  --color-info2: #50c0ff;

  --color-success: #28a745;
  --color-success-light: #2eb34d;
  --color-highlight: #fffb00;
  --color-highlightold: #f7f583;
  --color-highlightold222: #fffb00;

  --color-danger: #6d0707;

  /* --color-primary: #dc3545; */
  /* --color-secondary: #6c757d; */
  /* --color-accent: #8a949c;
  
   */
  /* --color-info: #007bff; */
  /* --color-info-dark: #024d9c;
  --color-info-light: #4f9ff5;
  --color-orange: #f79502; */
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

/* Typography */

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 2rem;
  line-height: 1.5;
  letter-spacing: 1px;
}

.highlight {
  color: var(--color-highlight);
}

.highlight-imp {
  color: var(--color-highlight) !important;
}

.highlightold {
  color: var(--color-highlightold);
}

.highlightold333 {
  color: var(--color-highlightold222);
}

.primary {
  color: var(--color-blue);
}

.success {
  color: var(--color-success);
}

.secondary {
  color: var(--color-secondary);
}

.danger {
  color: var(--color-danger);
}

.crossed {
  text-decoration: line-through;
}

.underline {
  text-decoration: underline;
}

.bolder {
  font-weight: bold;
}

.bolder-imp {
  font-weight: bold !important;
}

h1,
h2,
h3,
h4 {
  margin-bottom: 1rem;
  margin-top: 1rem;
  line-height: 1.4;
  font-weight: normal;
  letter-spacing: 1.5px;
}

h1 {
  font-size: 3rem;
  text-align: center;
  font-weight: bold;
}

h2 {
  font-size: 3rem;
  margin: 5rem 0 6rem;
  text-transform: uppercase;
  text-align: center;
}

h3 {
  font-size: 2.1rem;
  font-weight: bold;
}

h4 {
  font-size: 1.8rem;
  font-weight: bold;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  h1 {
    font-size: 2.7rem;
    text-align: center;
  }
}

@media screen and (min-width: 1200px) {
  body {
    font-size: 1.8rem;
  }
  h1 {
    font-size: 3.8rem;
    text-align: left;
  }
  h2 {
    font-size: 3rem;
  }
  h3 {
    font-size: 2rem;
    letter-spacing: 1px;
  }
}

/* Blocks */

.block {
  padding: 5rem 2rem;
}

.block1 {
  padding: 1rem 2rem 2rem;
}

.block--primary {
  background: var(--color-primary);
  color: #fff;
}

.block--dark {
  background: #000;
  color: #fff;
}

.block--light {
  background: var(--color-light);
  color: var(--color-secondary);
}

.block--secondary {
  background: var(--color-info);
  color: #fff;
}

.block--secondary .opinion__name,
.block--secondary .opinion__text,
.block--secondary .carousel__text,
.block--secondary .carousel__author {
  color: var(--color-secondary);
}

.block--skewed-left {
  padding-bottom: 10rem;
  /* wielokąt */
  /* lewy gorny i wskazowki zegara */
  /* x i y */
  clip-path: polygon(-1% -1%, 100% -1%, 100% 90%, 0% 100%);
}

.block.block--last {
  margin-bottom: 0;
  padding-bottom: 15rem;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
}

/* Countdown Timer */
.countdown-timer {
  display: flex;
  column-gap: 1rem;
  row-gap: 1rem;
  justify-content: center;
  font-size: 2.4rem;
  color: rgb(228, 59, 44);
  flex-wrap: wrap;
  margin-top: 1rem;
}

.countdown-timer__element {
  background: rgb(255, 223, 70);
  padding: 1.5rem 2rem 1.5rem;
  border-radius: 10px;
  width: 60px;
  font-weight: bold;
}

.countdown-timer__label {
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: normal;
}

.countdown__flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .countdown-timer {
    font-size: 2.4rem;
  }

  .countdown-timer__label {
    font-size: 0.8rem;
  }
}

/* Grids */

.grid {
  display: grid;
}

.grid--fit {
  align-items: start;
}

@media screen and (min-width: 768px) {
  .grid--1x2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .grid--1x3 {
    grid-template-columns: repeat(3, 1fr);
  }

  .grid--1x4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Cards */

.card {
  border-radius: 7px;
  overflow: hidden;
  margin: 1.5rem 2rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background: #fff;
}

.card__header {
  font-size: 2rem;
}

.card__header--flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card__module-number {
  margin-top: 2rem;
  text-transform: uppercase;
}

.card__module-name {
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

.card__icon {
  margin-right: 1rem;
  color: var(--color-info-dark);
  font-size: 3rem;
}

.card__header,
.card__body {
  padding: 3rem 4rem;
}

.opinion-block .card__body {
  padding: 2rem 2rem;
}

.card--accent .card__header {
  background: var(--color-info-light);
  color: #fff;
}

.card--success .card__header {
  background: var(--color-success);
  color: #fff;
}

.card__image {
  width: 100px;
}

.card__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.card__item {
  font-weight: bold;
  color: var(--color-secondary);
  font-size: 1.5rem;
  padding-bottom: 1rem;
}

.card__lesson {
  margin-right: 0.5rem;
  text-transform: uppercase;
  font-weight: normal;
}

@media screen and (min-width: 768px) {
  .card__header {
    font-size: 2.2rem;
  }

  .card {
    margin: 2rem 1.2rem;
  }
}

/* Menu */

.menu {
  padding: 0;
}

.menu__list {
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.3rem;
  margin: 0;
  padding: 0;
}

.menu__link {
  text-decoration: none;
  color: var(--color-secondary);
}

.menu__link:hover {
  color: #000;
}

.menu__icon {
  color: #000;
  border: 1px solid #fff;
  padding: 0.5rem 1.5rem;
  border-radius: 3px;
  margin: 1rem;
  margin-left: 2rem;
  opacity: 0.5;
  font-size: 2rem;
  cursor: pointer;
  transition: box-shadow 0.15s;
}

.menu__icon:hover {
  color: var(--color-secondary);
}

.collapsible__content {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.3s linear, opacity 0.3s linear;
}

.collapsible--expanded .collapsible__content {
  max-height: 100vh;
  opacity: 1;
}

.collapsible--expanded .menu__icon {
  opacity: 1;
  box-shadow: 0 0 3px 2px #ffffff;
  border-radius: 5px;
}

.collapsible__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.collapsible__arrow {
  font-size: 2.7rem;
  cursor: pointer;
  color: #747474;
  transform: rotate(0);
  transition: transform 0.3s linear;
}

.collapsible--expanded .collapsible__arrow {
  transform: rotate(90deg);
}

@media screen and (min-width: 900px) {
  .menu__icon {
    display: none;
  }

  .menu__list {
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 2rem;
    padding: 2rem 0;
    max-height: 100%;
    opacity: 1;
    margin: 0;
  }

  .menu__link {
    margin: 0;
  }

  .collapsible--expanded .collapsible__content {
    margin-bottom: 0;
  }

  .menu {
    padding: 0 5rem;
  }
}

/* Heading */
.heading {
  padding: 1rem 0;
}

.heading_left {
  margin: 0 2rem;
}

.heading__top {
  margin: 0 2rem;
}

.heading__top h1 {
  text-align: center;
}

.heading.heading--small-padding {
  padding-top: 5rem;
}

.heading__bottom {
  margin-top: 4rem;
}

.heading_third {
  text-align: center;
  margin: 2rem 0 2rem;
  font-size: 1.4rem;
}

.heading_right {
  margin: 4rem;
  justify-content: center;
  display: flex;
  align-items: center;
}

.heading_right--wait img {
  max-width: 300px;
}

.heading__main-text {
  color: var(--color-orange);
}

.heading_secondary-text {
  color: var(--color-orange);
  display: inline-block;
  min-height: 90px;
  margin-top: 3rem;
  text-align: center;
  font-size: 1.7rem;
  font-weight: bold;
  letter-spacing: 2.1px;
}

.heading_img {
  width: 300px;
}

@media screen and (min-width: 768px) {
  .heading_third {
    margin: 2rem 0 2rem;
    font-size: 1.8rem;
  }

  .heading_left {
    margin: 0 3rem;
  }

  .heading_secondary-text {
    margin-top: 2rem;
    font-size: 1.5rem;
    text-align: center;
  }

  .heading_right {
    margin: 0 3rem;
  }

  .heading_left {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .heading {
    padding: 5rem 0;
  }

  .heading__bottom {
    margin-top: 10rem;
  }
}

@media screen and (min-width: 1200px) {
  .heading_secondary-text {
    margin-top: 4rem;
    font-size: 1.9rem;
    text-align: left;
  }

  .heading_left {
    margin: 0 1rem 0 0;
  }

  .heading_right {
    margin: 0;
    padding-left: 3rem;
  }

  .heading {
    padding: 8rem 0 7rem;
  }

  .heading__top {
  }

  .heading__bottom {
  }
}

.simple__flex {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
}

/* Start course block */
.start-course {
  margin: 0;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.start-course__closed {
  margin: 0;
  margin-top: -5rem;
  padding-bottom: 8rem;
}

.start-course__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.start-course__btn {
  background: rgb(160, 44, 33);
  text-decoration: none;
  padding: 1rem 1.5rem;
  font-size: 2rem;
  text-transform: uppercase;
  color: #fff;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  border: 1px solid var(--color-danger);
  cursor: pointer;
}

.start-course__btn--wait {
  background: var(--color-blue);
  border: 1px solid var(--color-blue);
}

.start-course__btn2 {
  background: var(--color-success);
  text-decoration: none;
  padding: 2rem 3.3rem;
  text-transform: uppercase;
  color: #fff;
  border-radius: 15px;
  display: block;
  font-weight: bold;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  cursor: pointer;
}

.start-course__btn:hover {
  background: rgb(177, 54, 42);
}

.start-course__btn--wait:hover {
  background: #3a6ff5;
}

.start-course__btn--info {
  background: var(--color-primary);
}

.start-course__btn--info:hover {
  background: var(--color-third);
}

.start-course__label {
  display: block;
  margin: 3rem auto;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  font-size: 1.8rem;
}

/* tylko pod zamkniecie */
.start-course__label-info {
  display: block;
  margin: 3rem auto;
  text-align: center;
  font-size: 1.7rem;
}

/* tylko pod zamkniecie --------------------- */

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .start-course__btn {
    padding: 2rem 2rem;
    font-size: 2rem;
    margin: 2rem 4rem 1rem 4rem;
  }

  .start-course__btn2 {
    padding: 2rem 2rem;
    font-size: 5rem;
  }
}

@media screen and (min-width: 1200px) {
  .start-course__btn {
    padding: 1rem 2rem;
    font-size: 4rem;
  }

  .start-course__btn2 {
    padding: 2rem 5rem;
    font-size: 5rem;
  }

  .start-course__label {
    font-size: 2rem;
  }
}

/* Author block */
.author__img {
  border-radius: 40%;
  align-self: center;
  margin: 0 auto;
}

.author__info {
  margin-top: 3rem;
  font-size: 1.6rem;
  line-height: 1.7;
}

.author__info2 {
  margin-top: 3rem;
  font-size: 1.6rem;
  line-height: 1.7;
}

.author__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.author__item {
  padding-left: 4rem;
  position: relative;
}

.author__item::before {
  content: "➡";
  position: absolute;
  left: 1.2rem;
}

@media screen and (min-width: 1024px) {
  .author__info {
    grid-column: 1 / span 3;
    font-size: 1.6rem;
    margin-top: 0;
  }

  .author__info2 {
    font-size: 1.6rem;
    margin-top: 0;
    grid-column: span 2;
  }
}

/* Opinions */
.opinion-block {
  padding-bottom: 10rem;
}

.opinion {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.opinion__author {
  display: flex;
  justify-content: start;
  align-items: center;
}

.opinion__img {
  height: 50px;
  border-radius: 40px;
}

.opinion__name {
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 1rem;
}

.opinion__text {
  font-size: 1rem;
  padding-left: 0;
  display: flex;
  align-items: center;
}

.opinion__stars {
  color: var(--color-primary-old);
}

.people {
  display: grid;
  grid-template-columns: repeat(6, 50px);

  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}

.people__outer {
  width: 50px;
  height: 60px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.people__image {
  max-width: 50px;
}

@media screen and (min-width: 598px) {
  .people {
    grid-template-columns: repeat(9, 50px);
  }
}

@media screen and (min-width: 748px) and (max-width: 820) {
  .people {
    grid-template-columns: repeat(9, 65px);
  }

  .people__outer {
    width: 65px;
    height: 80px;
  }

  .people__image {
    max-width: 65px;
  }
}

@media screen and (min-width: 821px) and (max-width: 1139px) {
  .people {
    grid-template-columns: repeat(9, 75px);
  }

  .people__outer {
    width: 75px;
    height: 85px;
  }

  .people__image {
    max-width: 75px;
  }
}

@media screen and (min-width: 1140px) {
  .people {
    grid-template-columns: repeat(18, 50px);
  }
}

.carousel {
  margin: 0 auto;
  margin-top: 4rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 60vw;
  overflow: hidden;
  position: relative;
}

.carousel__all {
  display: inline-flex;
  transform: translateX(0);
  transition: transform 0.5s ease-in-out;
}

.carousel__content {
  width: 60vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 3rem 4rem;
  text-align: center;
  background: #fff;
}

.carousel__text {
  display: block;
  color: var(--color-accent);
  font-size: 1rem;
}

.carousel__author {
  color: var(--color-accent);
  font-weight: bold;
  font-size: 1.7rem;
}

.carousel__course {
  color: var(--color-success-light);
  font-size: 1.2rem;
}

.carousel__arrow-right {
  position: absolute;
  top: 50%;
  right: 1rem;
}

.carousel__arrow-left {
  position: absolute;
  top: 50%;
  left: 1rem;
}

.carousel__arrow-right,
.carousel__arrow-left {
  font-size: 2rem;
  cursor: pointer;
  color: var(--color-secondary);
  opacity: 0.5;
}

.carousel__dots {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
}

.carousel__dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: var(--color-secondary);
  opacity: 0.5;
  border-radius: 50%;
}

.carousel__dot--fill {
  opacity: 1;
}

@media screen and (min-width: 1024px) {
  .carousel__text {
    font-size: 1rem;
  }

  .carousel__content {
    padding: 2rem 6rem 4rem;
  }
}

@media screen and (min-width: 1125px) {
  .opinion__author {
  }

  .opinion__text {
  }
}

/* Path */
.path__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.path__image {
  margin: 5rem 0 3rem;
  max-width: 100%;
}

.path__text {
  color: var(--color-secondary);
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
}

@media screen and (min-width: 768px) {
  .path__text {
    color: var(--color-secondary);
    font-size: 1.6rem;
  }
}

/* Benefits */
.benefits__art {
  margin: 10rem 0;
}

.benefits__info {
  margin: 0 3rem;
}

.benefits__text {
  font-size: 1.6rem;
  letter-spacing: 0.2rem;
  color: var(--color-accent);
}

.benefits__image {
  justify-self: center;
  order: 2;
  margin-top: 3rem;
  max-width: 400px;
}

@media screen and (min-width: 768px) {
  .benefits__info {
    order: 1;
  }

  .benefits__image {
    order: 1;
  }
}

/* Summary */
.summary {
  padding: 5rem 0 12rem;
}

.summary__list {
  list-style: none;
  font-size: 2rem;
}

.summary__item {
  padding: 1rem 2rem;
}

.summary__item::before {
  content: "✅";
}

.summary__text {
  font-weight: bold;
  color: #d7dadd;
}

.summary__price {
  color: var(--color-primary);
}

.summary__full-value {
  color: var(--color-primary);
  text-decoration: line-through;
  font-size: 2.1rem;
}

.summary__normal-value {
  color: var(--color-primary);
  font-size: 2.3rem;
}

.summary__full-price {
  font-weight: bold;
  color: var(--color-success);
  text-decoration: underline;
  font-size: 2.7rem;
}

/* Contents */
.contents {
  padding: 5rem 0 12rem;
}

.contents__list {
  list-style: none;
  font-size: 2rem;
  text-align: center;
}

.contents__item {
  padding: 1rem 2rem;
}

.contents__item::before {
  content: "➡";
}

.contents__text {
  font-weight: bold;
  color: var(--color-orange);
}

.contents__price {
  color: var(--color-primary);
}

.contents__full-value {
  color: var(--color-primary);
  text-decoration: line-through;
  font-size: 2.1rem;
}

.contents__normal-value {
  color: var(--color-primary);
  font-size: 2.3rem;
}

.contents__full-price {
  font-weight: bold;
  color: var(--color-success);
  text-decoration: underline;
  font-size: 2.7rem;
}

/* Why */
.why {
  padding: 5rem 0 12rem;
}

.why__list {
  list-style: none;
  font-size: 2rem;
  text-align: center;
}

.why__item {
  padding: 1rem 2rem;
}

.why__item::before {
  content: "👉";
}

.why__text {
  color: #000;
}

.why__price {
  color: var(--color-primary);
}

.why__full-value {
  color: var(--color-primary);
  text-decoration: line-through;
  font-size: 2.1rem;
}

.why__normal-value {
  color: var(--color-primary);
  font-size: 2.3rem;
}

.why__full-price {
  font-weight: bold;
  color: var(--color-success);
  text-decoration: underline;
  font-size: 2.7rem;
}

/* Community */
.community {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
}

.community__icon {
  font-size: 5rem;
}

.community__count {
  font-size: 7rem;
  font-weight: bold;
}

.community__label {
  font-size: 1.4rem;
}

.community__icon--danger {
  color: #dc3545;
}

.community__icon--primary {
  color: #2762f8;
}

.community__icon--success {
  color: #28a745;
}

/* Quote */
.quote__block {
  text-align: center;
  position: relative;
}

.quote__icon {
  position: absolute;
  left: -3rem;
  top: -3rem;
  font-size: 10rem;
  opacity: 0.4;
}

.quote__text {
  font-size: 1.6rem;
}

.quote__author {
  display: block;
  font-style: italic;
  margin-top: 2rem;
  font-size: 1.8rem;
}

@media screen and (min-width: 768px) {
  .quote__text {
    font-size: 2.3rem;
  }

  .quote__author {
    font-size: 2.4rem;
  }
}

/* program */
.program__grid {
  gap: 5rem;
}

.program__image {
  max-width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
}

@media screen and (min-width: 768px) {
  .program__image {
    padding-left: 0;
    padding-right: 0;
  }
}

/* example */
.example__grid {
  gap: 5rem;
}

.example__div {
  padding-left: 3rem;
  padding-right: 3rem;
}

.example__div2 img {
  width: 100%;
}

.example__text {
  text-align: center;
  font-size: 1.3rem;
}

@media screen and (min-width: 768px) {
  .example__div {
    padding-left: 0;
    padding-right: 0;
  }

  .example__text {
    text-align: center;
    font-size: 1.5rem;
  }
}

/* Security */
.security__header {
  text-align: center;
  margin-bottom: 3rem;
  text-transform: uppercase;
}

.security__text {
  text-align: center;
  font-size: 1.4rem;
}

.security__payment,
.security__customer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1rem;
}

.security__image {
  margin: 1rem 0 4rem 0;
  width: 150px;
}

@media screen and (min-width: 768px) {
  .security__payment {
    margin-top: 0;
  }
}

/* Faq */

.faq__section {
  margin-top: 1rem;
}

.faq__text {
  font-size: 1.6rem;
  color: #747474;
}

.faq__email {
  color: var(--color-danger);
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  .faq__text {
    font-size: 1.8rem;
  }
}

/* Footer */
.footer {
  text-align: center;
  background: #000;
  color: #fff;
  padding: 2rem 0;
  font-size: 1.5rem;
}

.footer__link {
  color: #fff;
  text-decoration: none;
}

.footer__link:hover {
}

/* modal, tylko zamkniecie */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 200px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  position: relative;
  background-color: #fff;
  margin: auto;
  padding: 5px 20px 40px;
  border: 1px solid #888;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  animation-name: animatetop;
  animation-duration: 0.4s;
}

/* @-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
} */

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal__heading {
  margin: 5rem 3rem 0 3rem;
  text-align: center;
  line-height: 1.5;
  font-size: 2rem;
}

.modal__form {
  margin: 3rem 3rem 0 3rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.modal__input {
  padding: 1rem 2rem;
  border-radius: 5px;
  border: 1px solid var(--color-secondary);
  font-size: 1.7rem;
}

.modal__submit {
  padding: 1rem 2rem;
  background: #28a745;
  border: 0;
  color: #fff;
  border-radius: 5px;
  font-size: 2.1rem;
  cursor: pointer;
}

.modal__submit:hover {
  background: #31c553;
}

.modal__info {
  display: block;
  margin: 2rem 5rem 2rem;
  color: #6c757d;
  font-size: 1.2rem;
  text-align: center;
  line-height: 1.1;
}

.modal__success {
  display: none;
  margin: 2rem 5rem 2rem;
  color: var(--color-danger);
  font-size: 1.3rem;
  text-align: center;
  line-height: 1.3;
  font-weight: bold;
}

.modal__icon {
  color: #000;
  margin-right: 1px;
}

.loading-overlay {
  display: none;
  background: rgba(255, 255, 255, 0.3);
  width: 90%;
  height: 90%;
  position: absolute;
  z-index: 999;
  justify-content: center;
  align-items: center;
}

.loading-gif {
  z-index: 9999;
  border: 13px solid #f3f3f3;
  border-top: 13px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 0.6s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 768px) {
  .modal-content {
    width: 600px;
  }

  .modal__heading {
    font-size: 3rem;
  }

  .modal__input {
    font-size: 2.1rem;
  }

  .modal__info {
    font-size: 1.3rem;
  }

  .modal__success {
    font-size: 1.4rem;
  }
}

.privace,
.regulamin {
  margin-top: 10rem;
  margin-bottom: 10rem;
  font-size: 1.5rem;
}

.regulamin ul {
  list-style: none;
}

.privace h1,
.regulamin h1 {
  margin-bottom: 4rem;
  text-align: center;
  font-size: 4rem;
}

.privace h2,
.regulamin h2 {
  margin-top: 8rem;
  margin-bottom: 4rem;
  font-size: 3rem;
}

.regulamin h3 {
  margin-top: 8rem;
  margin-bottom: 4rem;
  font-size: 2.1rem;
}

.orange {
  color: var(--color-orange);
  font-weight: bold;
}

.ebook {
  display: flex;
  justify-content: center;
}

.ebook_img {
  height: 200px;
}

.ebook_img2 {
  height: 150px;
}

@media screen and (min-width: 768px) {
  .ebook_img {
    height: 400px;
  }

  .ebook_img2 {
    height: 300px;
  }
}

/* pain */

.pain_p {
  text-align: left;
  margin: 4rem 0;
}

/* product_p */

.product_p {
  text-align: center;
  margin: 4rem 0;
}

.product__outer-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product__image {
  width: 200px;
  /* background: var(--color-primary);
  padding: 1rem;
  border-radius: 20px; */
}

/* plans */

/* Plans Block */
/* .plans .grid {
  gap: 8rem 4rem;
} */

.plans {
  display: flex;
  justify-content: center;
  align-items: center;
}

.plan {
  border: 2px solid #4c70cc;
  background: var(--color-light);
  padding: 1rem 10px;
  width: 98%;
}

.plan--dark {
  background: rgb(47, 47, 47);
  color: #fff;
  width: 1140px;
  border: 0;
}

.plan--light {
  width: 1140px;
}

.plan__header {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  padding: 1rem 1.5rem;
}

.plan__header-text {
  font-size: 4rem;
}

.plan__header-span {
  margin-top: 2rem;
}

.plan__line {
  border-top: 2px solid var(--color-success);
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.bbbbb .plan__line {
  border-top: 2px solid var(--color-third);
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.highlight-success {
  color: var(--color-success);
}

.plan__image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0 5rem;
}

.plan--dark .product__image {
  width: 50%;
}

.plan__image-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
}

@media screen and (min-width: 768px) {
  .plan {
    padding: 2rem 4rem;
    width: 800px;
  }

  .plan__header {
    padding: 2rem 3rem;
  }

  .plan__header-text {
    font-size: 5rem;
  }

  .plan--dark {
    width: 1140px;
  }

  .plan--light {
    width: 1140px;
  }

  .plan__image-list {
    flex-direction: row;
  }
}

.card2 {
  border-radius: 7px;
  box-shadow: 0 0 20px 10px #f3f3f3;
  overflow: hidden;
  background: #fff;
  width: 100%;
}

.card2__header,
.card2__body {
  padding: 2rem 7%;
}

.card2__header {
  text-align: center;
}

.card2--primary .card2__header {
  background: var(--color-primary);
  color: #fff;
}

.card2--secondary .card2__header {
  background: var(--color-secondary);
  color: #fff;
}

.card2--primary .badge--primary {
  background: #126de4;
}

.card2--secondary .badge--secondary {
  background: #02cdf1;
}

/* Badges */
.badge {
  border-radius: 20px;
  font-size: 2rem;
  font-weight: 600;
  padding: 0.5rem 2rem;
  white-space: nowrap;
}

.badge--primary {
  background: var(--color-primary);
  color: #fff;
}

.badge--secondary {
  background: var(--color-secondary);
  color: #fff;
}

.badge--small {
  font-size: 1.6rem;
  padding: 0.5rem 1.5rem;
}

@media screen and (min-width: 1024px) {
  .badge {
    font-size: 1.5rem;
  }

  .badge--small {
    font-size: 1.2rem;
  }
}

/* Lists */
.list {
  list-style: none;
  padding-left: 0;
  font-size: 1.7rem;
}

.list--inline .list__item {
  display: inline-block;
  margin-right: 2rem;
}

.list--tick {
  padding-left: 1rem;
  color: var(--color-headings);
  font-size: 11rem;
  margin-top: 0;
  margin-bottom: 0;
}

.list--tick .list__item {
  padding-left: 0.5rem;
  margin-bottom: 1rem;
}

.list__flex {
  display: flex;
  column-gap: 1rem;
}

.list__item-img {
  width: 30px;
  height: 30px;
  background: #fff;
  padding: 5px;
  border-radius: 50%;
}

.plan--dark .list__item-img {
  background: transparent;
  width: 25px;
  height: 25px;
}

.list__item {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.list__flex span {
  font-weight: bold;
  color: #3d3d3d;
  font-size: 1.7rem;
}

.aaaaa .list__flex span {
  font-size: 1.5rem;
}

.plan--dark .list__flex span {
  color: #fff;
  font-weight: normal;
}

.plan--light .list__flex span {
  font-weight: normal;
}

.list__secondary {
  font-size: 1.5rem;
  color: #3f3e3e;
  margin-bottom: 1rem;
}

@media screen and (min-width: 768px) {
  .list {
    font-size: 1.8rem;
  }

  .list--tick {
    padding-left: 3rem;
  }

  .plan--dark .list__item-img {
    width: 40px;
    height: 40px;
  }

  .aaaaa .plan--dark .list__item-img {
    width: 30px;
    height: 30px;
  }
}

@media screen and (min-width: 1024px) {
  .list--tick .list__item {
    padding-left: 0;
  }
}

/* Plans */

.plan__name {
  color: #fff;
  margin: 0;
  font-weight: 500;
  font-size: 2.4rem;
}

.plan__price {
  margin-top: 4rem;
  font-weight: bold;
  font-size: 4rem;
  text-align: center;
  color: #3d3d3d;
}

.plan__price--closed {
  text-decoration: line-through;
}

.plan__nameapp {
  margin-top: 4rem;
  font-weight: bold;
  font-size: 4rem;
  text-align: center;
  text-transform: uppercase;
}

.plan__zwrot {
  margin-top: 0.5rem;
  font-weight: bold;
  font-size: 3rem;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-success);
}

.plan__raty {
  margin-top: 1rem;
  color: #3d3d3d;
  font-size: 1.6rem;
  text-align: center;
}

.plan--dark .plan__raty {
  color: #fff;
}

.plan--dark .plan__price {
  color: #fff;
  margin-bottom: 3rem;
  position: relative;
}

.plan__now {
  margin-top: 1rem;
  font-weight: bold;
  font-size: 4rem;
  text-align: center;
}

.plan__billing-cycle {
  font-size: 2rem;
  font-weight: 300;
  opacity: 0.8;
  margin-right: 1rem;
}

.plan__description {
  font-size: 2rem;
  font-weight: 300;
  letter-spacing: 1px;
  display: block;
}

.plan .list__item {
  margin-bottom: 1rem;
}

.plan--popular .card__header {
  position: relative;
}

.plan-price-info {
  font-size: 1.5rem;
  text-align: center;
  padding-bottom: 5rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

.plan--dark .plan__price::before {
  content: "";
  position: absolute;
  left: 0;
  top: 30px;
  border-top: 2px solid #fff;
  width: 14rem;
}

.plan--dark .plan__price::after {
  content: "";
  position: absolute;
  right: 0;
  top: 30px;
  border-top: 2px solid #fff;
  width: 14rem;
}

@media screen and (max-width: 799px) {
  .plan--dark .plan__price::before {
    content: "";
    position: absolute;
    left: 0;
    top: 30px;
    border-top: 2px solid #fff;
    width: 4rem;
  }

  .plan--dark .plan__price::after {
    content: "";
    position: absolute;
    right: 0;
    top: 30px;
    border-top: 2px solid #fff;
    width: 4rem;
  }
}

@media screen and (min-width: 1024px) {
  .plan__name {
    font-size: 1.4rem;
  }

  .plan__price {
    font-size: 4rem;
  }

  .plan__billing-cycle {
    font-size: 1.6rem;
  }

  .plan__description {
    font-size: 1.7rem;
  }

  .plan-price-info {
    padding-top: 1rem;
    padding-bottom: 5rem;
    padding-left: 8rem;
    padding-right: 8rem;
  }
}

/* Buttons */
.btn {
  border-radius: 40px;
  border: 0;
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: 600;
  margin: 1rem 0;
  outline: 0;
  padding: 2rem 4vw;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}

.btn .icon {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  vertical-align: middle;
}

.btn--primary {
  background: var(--color-primary);
  color: #fff;
}

.btn--primary:hover {
  background: #3a8ffd;
}

.btn--secondary {
  background: var(--color-secondary);
  color: #fff;
}

.btn--secondary:hover {
  background: #05cdf0;
}

.btn--accent {
  background: var(--color-accent);
  color: #fff;
}

.btn--accent:hover {
  background: #ec3000;
}

.btn--outline {
  background: #fff;
  color: var(--color-headings);
  border: 2px solid var(--color-headings);
}

.btn--outline:hover {
  background: var(--color-headings);
  color: #fff;
}

.btn--stretched {
  padding-left: 6rem;
  padding-right: 6rem;
}

.btn--block {
  width: 100%;
  display: inline-block;
}

@media screen and (min-width: 1024px) {
  .btn {
    font-size: 1.5rem;
  }
}

.input-group .btn {
  margin: 4px;
}

.btn__raty {
  font-size: 1.4rem;
  color: #fff;
  opacity: 0.7;
  text-transform: none;
}

.plan__installments {
  text-align: center;
  font-size: 1.5rem;
  margin-top: 3rem;
  font-weight: normal;
  margin-bottom: 2rem;
}

/* plan__guarantee */

.plan__guarantee {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 2rem;
  margin-top: 2rem;
  padding: 0 2rem;
}

.plan__guarantee-image {
  width: 40px;
}

.plan__guarantee-text {
  font-weight: bold;
  font-size: 1.5rem;
}

@media screen and (min-width: 768px) {
  .plan__guarantee-text {
    font-size: 1.8rem;
  }

  .plan__guarantee-image {
    width: 70px;
  }

  .plan__guarantee {
    padding: 0;
  }
}

.plan__closed {
  font-weight: bold;
  font-size: 1.7rem;
  text-transform: uppercase;
}

.plan__info {
  font-size: 1.3rem;
  color: #272727;
}

.benefits__list {
}

.benefits__item {
  margin-top: 1.4rem;
  list-style: decimal;
}

.bootom-section {
  padding-bottom: 15rem;
}

.bootom-section__closed {
  padding-bottom: 5rem;
}

.agenda {
  column-gap: 3rem;
  row-gap: 4rem;
  font-size: 1.5rem;
  align-items: start;
}

.agenda__modul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.agenda__img {
  width: 150px;
}

.agenda__header {
  font-weight: bold;
  margin-top: 1.5rem;
  margin-bottom: 1.4rem;
  text-align: center;
  color: var(--color-primary);
}

.agenda__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.agenda__item {
  margin-top: 5px;
}

.agenda__lesson {
}

.sad {
  color: var(--color-highlightold);
  margin-right: 0.7rem;
}

.bigger {
  font-size: 2rem;
}

.frameworks {
  display: flex;
  justify-content: center;
  align-items: center;
}

.frameworks img {
  max-width: 100%;
}

.timer__closed {
  z-index: 99;
  position: fixed;
  bottom: 20px;
  left: 20px;
  border: 6px solid rgb(228, 59, 44);
  border-radius: 10px;
  padding: 2rem;
  background: #fff;
}

@media screen and (min-width: 768px) {
  .plan__infoaa {
    font-size: 1.8rem;
  }
}

.choise {
  margin-bottom: 5rem;
}

.plan__infoaa {
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 2;
}

.block1.aaaaa {
  margin-bottom: 5rem;
}

.plan__infoaa2 {
  font-size: 2rem;
}

.authorh2 {
  margin-top: 0;
  font-size: 2.5rem;
}

.author__info4444 {
  font-size: 1.5rem;
}

.plan__infoaa3 {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 4rem;
}
